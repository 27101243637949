export enum BankId {
  MELIBANK = 1,
  FUTRUEBANK = 2,
  SAMANBANK = 3,
  SADERATBANK = 4,
  MELATBANK = 5,
  SEPAHBANK = 6,
  PARSIANBANK = 7,
  EGHTESADNOVINBANK = 8,
  AGRIBANK = 9,
  MASKANBANK = 10,
  PASARGADBANK = 11,
  SANATVAMADANBANK = 12,
  TOSESADERATBANK = 13,
  SARMAYEBANK = 14,
  REFAHKARGARANBANK = 15,
  POSTBANK = 16,
  TOSETAVONBANK = 17,
  SINABANK = 18,
  CITYBANK = 19,
  DAYBANK = 20,
  TEJARATBANK = 21,
  MIDDLEEASTBANK = 22,
  GARDESHGARIBANK = 23,
  IRANZAMINBANK = 24,
  RESALARBANK = 25,
  MEHRIRANBANK = 26,
  KARAFARINBANK = 27,
  MOASESEETEBARINOUR = 28,
  MOASESEETEBARIMELAL = 29,
  BLUBANK = 30,
}

export enum BankIdPersian {
  MELIBANK = 'بانک ملی',
  FUTRUEBANK = 'بانک آینده',
  SAMANBANK = 'بانک سامان',
  SADERATBANK = 'بانک صادرات',
  MELATBANK = 'بانک ملت',
  SEPAHBANK = 'بانک سپه',
  PARSIANBANK = 'بانک پارسیان',
  EGHTESADNOVINBANK = 'بانک اقتصاد نوین',
  AGRIBANK = 'بانک کشاورزی',
  MASKANBANK = 'بانک مسکن',
  PASARGADBANK = 'بانک پاسارگاد',
  SANATVAMADANBANK = 'بانک صنعت و معدن',
  TOSESADERATBANK = 'بانک توسعه صادرات',
  SARMAYEBANK = 'بانک سرمایه',
  REFAHKARGARANBANK = 'بانک رفاه کارگران',
  POSTBANK = 'پست بانک',
  TOSETAVONBANK = 'بانک توسعه تعاون',
  SINABANK = 'بانک سینا',
  CITYBANK = 'بانک شهر',
  DAYBANK = 'بانک دی',
  TEJARATBANK = 'بانک تجارت',
  MIDDLEEASTBANK = 'بانک خاور میانه',
  GARDESHGARIBANK = 'بانک گردشگری',
  IRANZAMINBANK = 'بانک ایران زمین',
  RESALARBANK = 'بانک رسالت',
  MEHRIRANBANK = 'بانک قرض الحسنه مهر ایران',
  KARAFARINBANK = 'بانک کارآفرین',
  MOASESEETEBARINOUR = 'موسسه اعتباری نور',
  MOASESEETEBARIMELAL = 'موسسه اعتباری ملل',
  BLUBANK = 'بانک بلو',
}

export const baseImageBankUrl = '/assets/img/bank';
