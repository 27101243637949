import { OldDomainDetailTypeResponse } from '../modules/domainDetail/types';
import { OldDomainDetailsState } from '../redux/domainDetailsSlice';

const transformApiResponseToState = (
  response: OldDomainDetailTypeResponse,
): OldDomainDetailsState => {
  const apiData = response;
  return {
    domainId: apiData.domainId,
    domainName: apiData.domainName,
    domainPart: apiData.domainPart,
    domainLength: apiData.domainLength,
    tldPart: apiData.tldPart,
    domainPriceInToman: apiData.domainPriceInToman,
    domainPriceInUSD: apiData.domainPriceInUSD,
    persianName: apiData.persianName,
    visitedCount: apiData.visitedCount,
    priceType: apiData.priceType,
    usdtToToman: apiData.usdtToToman,
    commentInPersian: apiData.commentInPersian,
    commentInEnglish: apiData.commentInEnglish,
    isSet: true, // وقتی داده از API آمده است، مقدار isSet را true می‌کنیم
  };
};
export default transformApiResponseToState;
